import AccountBalanceWalletIcon from '@material-ui/icons/esm/AccountBalanceWallet';
import Button from '@material-ui/core/esm/Button';
import Dialog from '@material-ui/core/esm/Dialog';
import DialogActions from '@material-ui/core/esm/DialogActions';
import DialogContent from '@material-ui/core/esm/DialogContent';
import DialogTitle from '@material-ui/core/esm/DialogTitle';
import InputAdornment from '@material-ui/core/esm/InputAdornment';
import PhoneIcon from '@material-ui/icons/esm/Phone';
import TextField from '@material-ui/core/esm/TextField';
import withStyles from '@material-ui/styles/esm/withStyles';
import MUIOutlinedInput from '@material-ui/core/esm/OutlinedInput';
import CircularProgress from '@material-ui/core/esm/CircularProgress';
import Snackbar from '@material-ui/core/esm/Snackbar';
import SnackbarContent from '@material-ui/core/esm/SnackbarContent';

import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createNewSession, getSessionStatus } from '../../providers/api';
import { newSessionSchema } from '../../utils/validationSchemas';
import {
  setCreateNewSession,
  setUpdateSessionData,
} from '../../reducers/ThemeOptions';
import StatusFormat from '../../components/Format/StatusFormat';

const INTERVAL_BETWEEN_ATTEMPTS_FETCH_SESSION_STATUS = 10000; // 10s
const NUMBER_ATTEMPTS_FETCH_SESSION_STATUS = 60; // 10s between attempts. 6 attempts per minute * 10 minutes

const styles = {
  errorText: {
    color: 'red',
  },
};

const OutlinedInput = withStyles((theme) => ({
  input: {
    padding: '10px 12px 10px 0',
  },
}))(MUIOutlinedInput);

const CreateNewSession = ({
  filterMerchant,
  createNewSessionRedux,
  setCreateNewSession,
  updateSessionData,
  setUpdateSessionData,
}) => {
  const intl = useIntl();

  const formRef = useRef();
  const [newSessionUrl, setNewSessionUrl] = useState(null);
  const [sessionStatus, setSessionStatus] = useState('');
  const [isStatusReceived, setIsStatusReceived] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [sessionStatusFetchInterval, setSessionStatusFetchInterval] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleSendForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleCloseDialogNewSessionWindow = async () => {
    setCreateNewSession(false);
    setNewSessionUrl(null);

    setUpdateSessionData(updateSessionData + 1);
  };

  const handleCreateNewSession = async (newSessionData) => {
    const { data } = await createNewSession(filterMerchant, {
      ...newSessionData,
      phone: `+569${newSessionData.phone}`,
      isInStoreSession: true,
    });
    const { url, sessionId, isSendWhatsAppMessageSuccess } = data;

    if (!isSendWhatsAppMessageSuccess) {
      setIsAlertVisible(true);
      return;
    }

    setNewSessionUrl(url);
    setCreateNewSession(false);

    let i = 0;
    const timer = setInterval(async () => {
      try {
        const dataSessionStatus = await getSessionStatus(sessionId);
        const { status } = dataSessionStatus.data;
        switch (status) {
          case 'CONFIRMED':
          case 'ERROR':
          case 'DENIED':
          case 'ABORTED':
          case 'EXPIRED':
          case 'FAILED':
            clearInterval(timer);
            setSessionStatus(status);
            setIsStatusReceived(true);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log('error while getting session status', error);
      }

      i += 1;
      if (i === NUMBER_ATTEMPTS_FETCH_SESSION_STATUS) {
        setIsTimeOut(true);
        clearInterval(timer);
      }
    }, INTERVAL_BETWEEN_ATTEMPTS_FETCH_SESSION_STATUS);
    setSessionStatusFetchInterval(timer);
  };

  const handleCloseNewSessionWindow = async () => {
    clearInterval(sessionStatusFetchInterval);

    setSessionStatusFetchInterval(null);
    setIsStatusReceived(false);
    setSessionStatus('');
    setIsTimeOut(false);
    setNewSessionUrl(null);
  };

  const handleCloseAlert = async () => {
    setIsAlertVisible(false);
  };

  return (
    <>
      <Snackbar
        open={isAlertVisible}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <SnackbarContent
            style={{ backgroundColor: '#5383ff' }}
            message={<FormattedMessage id="WhatsApp message could not be delivered" />}
          />
      </Snackbar>
      <Dialog
        fullWidth
        open={createNewSessionRedux}
        onClose={handleCloseDialogNewSessionWindow}
      >
        <DialogTitle>
          <div className="card-title font-weight-bold text-center">
            <h4>
              <FormattedMessage id="Create new session" />
            </h4>
          </div>
        </DialogTitle>

        <Formik
          initialValues={{ phone: '', amount: 0 }}
          innerRef={formRef}
          validationSchema={newSessionSchema}
          onSubmit={handleCreateNewSession}
        >
          {({ errors, touched, isValid }) => (
            <>
              <DialogContent dividers>
                <Form>
                  <Field id="phone" name="phone">
                    {({ field }) => (
                      <>
                        <InputMask mask="9 999 9999" maskChar="" {...field}>
                          {(props) => (
                            <OutlinedInput
                              {...props}
                              fullWidth
                              error={errors.phone && touched.phone}
                              name="phone"
                              startAdornment={(
                                <InputAdornment position="start">
                                  <PhoneIcon />
                                  (+569){' '}
                                </InputAdornment>
                              )}
                            />
                          )}
                        </InputMask>
                        {errors.phone && touched.phone ? (
                          <div style={styles.errorText}>
                            <FormattedMessage id="You must enter a valid phone" />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Field>

                  <br />
                  <br />

                  <Field id="amount" name="amount">
                    {({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          error={errors.amount && touched.amount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountBalanceWalletIcon />
                              </InputAdornment>
                            ),
                          }}
                          label={<FormattedMessage id="Amount" />}
                          size="small"
                          type="number"
                          variant="outlined"
                          {...field}
                        />
                        {errors.amount && touched.amount ? (
                          <div style={styles.errorText}>
                            <FormattedMessage id={errors.amount} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Field>

                  <br />
                  <br />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  color="primary"
                  disabled={!isValid}
                  variant="contained"
                  onClick={handleSendForm}
                >
                  <FormattedMessage id="Create" />
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
      <Dialog
        fullWidth
        open={newSessionUrl}
        onClose={handleCloseNewSessionWindow}
      >
        <DialogTitle>
          <div className="card-title font-weight-bold text-center">
            <h4>
              <FormattedMessage id="Session url was sent to customer" />
            </h4>
          </div>
        </DialogTitle>

        <DialogContent dividers>
          {newSessionUrl}
          {' '}&nbsp;{' '}
          <CopyToClipboard
            text={newSessionUrl}
            onCopy={() => alert(intl.formatMessage({id: 'Copied'}))}
          >
            <Button
              disableElevation
              color="primary"
              size="large"
              variant="contained"
            >
              <FormattedMessage id="Copy" />
            </Button>
          </CopyToClipboard>
          <br />
          {isStatusReceived ? (
            <StatusFormat status={sessionStatus} />
          ) : isTimeOut ? (
            <FormattedMessage id="Timed out, check status on sessions page" />
          ) : (
            <div>
              <CircularProgress
                className="circular-progress"
                color="secondary"
                size={18}
              />{' '}
              <FormattedMessage id="Waiting for final status" />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleCloseNewSessionWindow}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  filterMerchant: state.Filters.sessionsFiltersMerchant,
  createNewSessionRedux: state.ThemeOptions.createNewSession,
  updateSessionData: state.ThemeOptions.updateSessionData,
});

const mapDispatchToProps = (dispatch) => ({
  setCreateNewSession: (payload) => dispatch(setCreateNewSession(payload)),
  setUpdateSessionData: (payload) => dispatch(setUpdateSessionData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewSession);
